import styled from 'styled-components';

import { black, white } from 'theme/colors';
import { container } from 'theme/sizes';
import { toRem } from 'utils/mixins';

export const Wrapper = styled.div`
  max-width: ${(props) => (props.isAmb ? '' : container.default)};
  width: 100%;
  height: calc(100vh - 85px);
  margin: ${(props) => (props.isAmb ? '0 auto' : `${toRem(140)} auto`)};
  position: relative;

  * {
    outline: none !important;
  }
`;

export const DotsWrapper = styled.ul`
  position: absolute;
  left: 0;
  transform: ${(props) => (props.isAmb ? '' : ` translate(-100%, 50%)`)};
  bottom: 50%;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 4;

  li {
    position: relative;
    width: ${toRem(5)};
    height: ${toRem(50)};
    overflow: hidden;
    margin: ${toRem(10)};
  }

  button {
    cursor: pointer;
    position: relative;
    width: 100%;
    height: 100%;
    display: block;
    background-color: ${(props) =>
      props.isAmb ? 'rgba(255,255,255, 0.2)' : `rgba(0, 0, 0, 0.2)`};
  }

  span {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-100%);
    background-color: ${(props) =>
      props.isAmb ? 'rgba(255,255,255, 0.8)' : `rgba(0, 0, 0, 0.8)`};
    width: 100%;
    height: 100%;
  }
`;

export const Prev = styled.button`
  position: absolute;
  bottom: 40px;
  z-index: 5;
  left: unset !important;
  right: 80px;
  cursor: pointer;
`;

export const Next = styled.button`
  position: absolute;
  right: 40px;
  bottom: 40px;
  z-index: 5;
  left: unset !important;
  cursor: pointer;
`;

export const Slide = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - 85px);

  background-color: ${black.default};

  .gatsby-image-wrapper {
    opacity: 0.6;

    div:first-child {
      padding-top: calc(100vh - 85px) !important;
    }

    picture {
      img {
        height: calc(100vh - 85px);
      }
    }
  }
`;

export const Box = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50vw;
  padding: ${toRem(50)};
  background-color: ${white.default};

  h2,
  p {
    color: ${black.default};
  }

  .button {
    display: inline-block;
    width: auto;
    margin-top: ${toRem(10)};
  }
`;
