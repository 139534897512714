import React, { useRef, useState, useEffect } from 'react';

import Slider from 'react-slick';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

import { Wrapper, Slide, Box, Prev, Next, DotsWrapper } from './Slider.styles';
import HTMLReactParser from 'html-react-parser';
import { Link } from 'gatsby';
import gsap, { Sine } from 'gsap';

import { white } from '../../theme/colors';

const BannerSlider = (props) => {
  //PROPS
  const { data } = props;
  //PROPS

  //STATES
  const [slide, setSlide] = useState(0);
  //STATES

  //REF
  const slider = useRef(null);
  //REF

  //FUNCS
  const nextSlide = () => {
    slider.current.slickNext();
  };

  const prevSlide = () => {
    slider.current.slickPrev();
  };

  const PrevArrow = () => {
    return (
      <Prev onClick={() => prevSlide()}>
        <FontAwesomeIcon icon={faChevronLeft} color={white.default} size="lg" />
      </Prev>
    );
  };

  const NextArrow = () => {
    return (
      <Next onClick={() => nextSlide()}>
        <FontAwesomeIcon
          icon={faChevronRight}
          color={white.default}
          size="lg"
        />
      </Next>
    );
  };
  //FUNCS

  const Dots = (e) => {
    const [active, setActive] = useState();

    useEffect(() => {
      setActive(slide);
    }, [slide]);

    return (
      <DotsWrapper isAmb={props.isAmb}>
        {data.map((dot, index) => {
          if (active === index) {
            gsap.to(`.dot${index}`, {
              duration: 8.2,
              css: { y: 0 },
              ease: Sine.easeOut,
            });
          }

          return (
            <li key={index}>
              <button onClick={() => slider.current.slickGoTo(index)}></button>
              <span className={`dot${index}`}></span>
            </li>
          );
        })}
      </DotsWrapper>
    );
  };

  //DEFS
  const settings = {
    dots: false,
    arrows: true,
    speed: 500,
    slidesToShow: 1,
    swipe: true,
    swipeToSlide: true,
    autoplay: true,
    autoplaySpeed: 8000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,

    afterChange: (e) => {
      setSlide(e);
    },
    beforeChange: (e) => {},
  };
  //DEFS

  return (
    <Wrapper isAmb={props.isAmb}>
      <Slider {...settings} ref={slider}>
        {data.map((slide, index) => {
          const image =
            slide.ambientes?.thumbnailImage?.localFile?.childImageSharp
              ?.gatsbyImageData;

          return (
            <Slide key={index}>
              {image && (
                <GatsbyImage
                  image={image}
                  alt={slide.ambientes?.thumbnailImage?.altText}
                />
              )}
              <Box>
                <h2>{slide.ambientes?.nome}</h2>
                {slide.ambientes?.thumbnailText &&
                  HTMLReactParser(slide.ambientes?.thumbnailText)}
                <Link className="button button--small" to={slide?.seo?.slug}>
                  Ver mais
                </Link>
              </Box>
            </Slide>
          );
        })}
      </Slider>
      <Dots />
    </Wrapper>
  );
};

export default BannerSlider;
